import React from "react";
import styles from "./Contact.module.css";
import { FaMediumM, FaLinkedinIn, FaGithub } from "react-icons/fa";

const Contact = () => {
  return (
    <div className={styles["contact-container"]}>
      <h2>Connect with me</h2>
      <div className={styles["contact-subtext"]}>
        If you're a developer/techie or just want to say hello, here are some of
        my other addresses on the web where we can connect :-)
      </div>
      <div className={styles["contact-icon-container"]}>
        <div className={styles["contact"]}>
          <a href="https://www.linkedin.com/in/anand-shanker-deb-372908136/">
            <FaLinkedinIn />
          </a>
        </div>

        <div className={styles["contact"]}>
          <a href="https://medium.com/@ananddeb23">
            <FaMediumM />
          </a>
        </div>

        <div className={styles["contact"]}>
          <a href="https://github.com/ananddeb23">
            <FaGithub />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

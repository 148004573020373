import signature from "../../img/sign.png";
import styles from "./TopBar.module.css";

const TopBar = () => {
  return (
    <div className={styles.container}>
      <img
        className={styles["signature-img"]}
        src={signature}
        alt="anand-e-signature"
      />
    </div>
  );
};

export default TopBar;

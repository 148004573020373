import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Favicon from "react-favicon";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  // Global settings:
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  once: false, // whether animation should happen only once - while scrolling down
  mirror: true,
});
ReactDOM.render(
  <React.StrictMode>
    <Favicon url="https://raw.githubusercontent.com/ananddeb23/ananddeb.com-assets/main/favicon.ico" />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

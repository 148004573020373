import { useState } from "react";
import Typist from "react-typist";
import { FaChevronDown } from "react-icons/fa";
import styles from "./Banner.module.css";

const Banner = (props) => {
  const [showButton, setShowButton] = useState(false);
  const toggleButton = () => {
    setShowButton(true);
  };
  const baseBannerBtnStyleClass = styles["banner-button"];
  const bannerBtnStyle = showButton
    ? `${baseBannerBtnStyleClass} ${styles.cusom}`
    : `${baseBannerBtnStyleClass} ${styles.hide}`;
  return (
    <div
      className={styles["banner-layout"]}
      data-aos="flip-down"
      data-aos-offset="200"
      data-aos-duration="1500"
    >
      <div className={styles["banner-body"]}>
        <span className={styles["bb-text"]}>
          Hi. I&apos;m <span className={styles["bb-text-highlight"]}>A </span>
          nand.
        </span>
        <br />
        <span className={styles["bb-subtext-container"]}>
          <span className={styles["bb-subtext"]}>
            I create solutions for the web
          </span>
        </span>
      </div>
      <div className={styles["banner-description"]}>
        <Typist
          avgTypingDelay={60}
          onTypingDone={toggleButton}
          cursor={{
            show: true,
            blink: true,
            element: "|",
            hideWhenDone: false,
            hideWhenDoneDelay: 1000,
          }}
        >
          I'm a software engineer who builds exceptional digital experiences.
          <br /> Presently, I'm helping clients with digital & cloud
          transformations and fullstack web development as a Senior Digital
          Analyst (SDE III) at McKinsey and Company.
        </Typist>
      </div>
      <div className={bannerBtnStyle} onClick={props.onDownButtonClick}>
        <FaChevronDown />
      </div>
    </div>
  );
};

export default Banner;

import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
  <div className={styles["footer-container"]}>© Anand Shanker Deb {new Date().getFullYear()}</div>
  );
};

export default Footer;

import Banner from "../Banner/Banner";
import Body from "../Body/Body";
import Footer from "../Footer/Footer";
import TopBar from "../TopBar/TopBar";
import Contact from "../Contact/Contact";

import { useRef } from "react";

import styles from "./Main.module.css";

const Main = () => {
  const bodyRef = useRef();
  const downButtonClickHandler = () => {
    bodyRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
    // window.scrollTo(0, bodyRef.current.offsetTop + 450)
    // bodyRef.current.getScrollableNode().children[0].scrollTop = 0;
    // window.scrollBy(200 , 0)
  };
  //   const cutom = (event) => {

  // //    var screenh = window.innerHeight;
  // // var toScroll = ''+screenh/10;
  // // bodyRef.current.scrollIntoView(true);
  // // window.scrollBy(0,toScroll);
  //   }
  return (
    <main className={styles.container}>
      <TopBar />
      <div className={styles.profile}>
        <Banner onDownButtonClick={downButtonClickHandler} />
        <Body ref={bodyRef} />
      </div>
      <Contact />
      <Footer />
    </main>
  );
};

export default Main;

import { Fragment } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import Main from "./components/Main/Main";

function App() {
  return (
    <Fragment className="App">
      <header>
        <Helmet>
          <title>Anand Shanker Deb</title>
          <meta
            name="description"
            content="Anand Shanker Deb  - I'm a software engineer who builds exceptional digital experiences"
          />
          <meta property="og:title" content="Anand Shanker Deb" />
          <meta property="og:image" content="./img/anand.jpg" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Anand Deb" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://ananddeb.com/" />
        </Helmet>
      </header>
      <Main />
    </Fragment>
  );
}

export default App;

import { FaExternalLinkAlt } from "react-icons/fa";
import styles from "./Projects.module.css";

const Projects = (props) => {
  const techList = props.tech.map((tech, index) => (
    <span key={`${tech}${index}`} className={styles["tech-item"]}>
      {tech}
    </span>
  ));

  const imageContainer = (
    <div className={styles["img-container"]}>
      <a href={props.url}>
        {" "}
        <div className={styles["img-wrapper"]}></div>
      </a>
      <img
        className={styles["img"]}
        src={props.img}
        alt={props.title.toLowerCase()}
      />
    </div>
  );

  const summaryStyle = props.flip
    ? `${styles.summary} ${styles["summary-align-flipped"]}`
    : `${styles.summary} ${styles["summary-align-normal"]}`;
  const techStyle = props.flip
    ? `${styles.tech} ${styles["tech-align-flipped"]}`
    : `${styles.tech} ${styles["tech-align-normal"]}`;
  const linkStyle = props.flip
    ? `${styles.links} ${styles["links-align-flipped"]}`
    : `${styles.links} ${styles["links-align-normal"]}`;

  const description = (
    <div className={styles.description}>
      <div className={styles.title}> {props.title}</div>
      <div className={summaryStyle}>{props.summary}</div>
      <div className={techStyle}>{techList}</div>
      <div className={linkStyle}>
        <a href={props.url} style={{ textDecoration: "none" }}>
          <FaExternalLinkAlt />
        </a>
      </div>
    </div>
  );

  const content = props.flip ? (
    <>
      {description}
      {imageContainer}
    </>
  ) : (
    <>
      {imageContainer}
      {description}
    </>
  );

  return <div className={styles["box-container"]}>{content}</div>;
};

export default Projects;

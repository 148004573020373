import React from "react";
import Projects from "../Projects/Projects";
import munchesImg from "./../../img/munches.png";
import neatTabsImg from "./../../img/neat_tabs.png";
import styles from "./Body.module.css";

const Body = React.forwardRef((props, ref) => {
  const projectsData = [
    {
      title: "Munches",
      summary:
        "A mobile first app which presents summarized news. Depending on the number of words in the news content, the articles are flipped automatically after a calculated duration of time.",
      url: "https://munches-ananddeb23.vercel.app/",
      tech: ["React", "NextJs", "REST", "NewsAPI"],
      img: munchesImg,
    },
    {
      title: "Neat Tabs",
      summary:
        "A chrome extension to Help in managing tabs by automatically closing unused and inactive ones",
      url:
        "https://chrome.google.com/webstore/detail/neat-tabs/fphcapbojemdkcfaddadfaekoejfalmi?hl=en",
      tech: ["Chrome", "Extension", "Javascript"],
      img: neatTabsImg,
    },
  ];

  const projects = projectsData.map((project, index) => (
    <Projects
      title={project.title}
      summary={project.summary}
      url={project.url}
      tech={project.tech}
      img={project.img}
      flip={index % 2 === 0}
    />
  ));

  return (
    <div className={styles["body-wrapper"]}>
      <div ref={ref}> </div>
      <div
        data-aos="flip-up"
        data-aos-offset="200"
        data-aos-duration="1500"
        className={styles["body-container"]}
      >
        <div className={styles["body-text"]}>
          <h2> About Me</h2>

          <div className={styles["body-picture-hexagon"]}></div>
          <div className={styles["body-text-intro"]}>
            <p>
              {" "}
              I have experience in implementing digital solutions for clients in
              the e-commerce, insurance, energy, and health domains. My
              professional interests are in software development (front-end &
              back-end) and systems design.
            </p>
            <p>
              I ♡ to <s>speak</s> code Javascript but work with other languages too.
            </p>
            <p>
              In my spare time, I like gardening, cooking and sometimes reading.
            </p>
          </div>
          <h3>Technologies that I work with</h3>
          <div className={styles["skill-container-outer"]}>
            <div className={styles["skill-container"]}>
              <ul className={styles["skill-ul"]}>
                <li className={styles["skill"]}> Javascript</li>
                <li className={styles["skill"]}> React</li>
                <li className={styles["skill"]}> Redux</li>
                <li className={styles["skill"]}> CSS </li>
                <li className={styles["skill"]}> HTML </li>
              </ul>
            </div>
            <div className={styles["skill-container"]}>
              <ul className={styles["skill-ul"]}>
                <li className={styles["skill"]}> NodeJS</li>
                <li className={styles["skill"]}> REST API</li>
                <li className={styles["skill"]}> GraphQL</li>
              </ul>
            </div>

            <div className={styles["skill-container"]}>
              <ul className={styles["skill-ul"]}>
                <li className={styles["skill"]}> Postgres</li>
                <li className={styles["skill"]}> MySQL</li>
                <li className={styles["skill"]}> ORMs</li>
                <li className={styles["skill"]}> Redis </li>
              </ul>
            </div>

            <div className={styles["skill-container"]}>
              <ul className={styles["skill-ul"]}>
                <li className={styles["skill"]}> GCP</li>
                <li className={styles["skill"]}> AWS </li>
                <li className={styles["skill"]}> Terraform</li>
              </ul>
            </div>
          </div>
          <h3>Highlights from some of the things I built</h3>
          <> {projects} </>
        </div>
      </div>
    </div>
  );
});

export default Body;
